import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import EventsView from "../views/EventsView.vue";
import LandingView from "../views/LandingView.vue";
import ClosedGroup from "../views/ClosedGroup.vue";
import ClosedAdvanced from "../views/ClosedAdvanced.vue";
import OpenGroup from "../views/OpenGroup.vue";
import OpenGroupSpecial from "../views/OpenGroupSpecial.vue";
import SistersCircle from "../views/SistersCircle.vue";
import ZartesHerz from "../views/ZartesHerz.vue";
import SlowDating from "../views/SlowDating.vue";
import DancingNight from "../views/DancingNight.vue";
import MassageNight from "../views/MassageNight.vue";
import CoupleNight from "../views/CoupleNight.vue";
import SeminarMar from "../views/SeminarMar.vue";
import SeminarMay from "../views/SeminarMay.vue";
import SeminarSep from "../views/SeminarSep.vue";
import SeminarNov from "../views/SeminarNov.vue";
import ZartesHerzTag from "../views/ZartesHerzTag.vue";
import InfoOnline from "../views/InfoOnline.vue";
import OrgasmicMeditation from "../views/OrgasmicMeditation.vue";
import OrgasmicMeditationNight from "../views/OrgasmicMeditationNight.vue";
import CuddleEvening from "../views/CuddleEvening.vue";
import TempleEvening from "../views/TempleEvening.vue";
import SingleSession from "../views/SingleSession.vue";
import WirView from "../views/WirView.vue";
import WelcomeView from "../views/WelcomeView.vue";
import ImpressumView from "../views/ImpressumView.vue";
import DatenSchutz from "../views/DatenSchutz.vue";
import AGBView from "../views/AGBView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: HomeView,
    children: [
      {
        path: "/home",
        component: LandingView,
      },
      {
        path: "/events",
        component: EventsView,
      },
      {
        path: "/closedgroup",
        component: ClosedGroup,
      },
      {
        path: "/advanced",
        component: ClosedAdvanced,
      },
      {
        path: "/opengroup",
        component: OpenGroup,
      },
      {
        path: "/party",
        component: OpenGroupSpecial,
      },
      {
        path: "/sisters",
        component: SistersCircle,
      },
      {
        path: "/zartesherz",
        component: ZartesHerz,
      },
      {
        path: "/dating",
        component: SlowDating,
      },
      {
        path: "/dancing",
        component: DancingNight,
      },
      {
        path: "/massage",
        component: MassageNight,
      },
      {
        path: "/couple",
        component: CoupleNight,
      },
      {
        path: "/tantra-march",
        component: SeminarMar,
      },
      {
        path: "/tantra-may",
        component: SeminarMay,
      },
      {
        path: "/tantra-september",
        component: SeminarSep,
      },
      {
        path: "/tantra-november",
        component: SeminarNov,
      },
      {
        path: "/zartesherztag",
        component: ZartesHerzTag,
      },
      {
        path: "/bom",
        component: OrgasmicMeditation,
      },
      {
        path: "/bomnight",
        component: OrgasmicMeditationNight,
      },
      {
        path: "/cuddle",
        component: CuddleEvening,
      },
      {
        path: "/temple",
        component: TempleEvening,
      },
      {
        path: "/online",
        component: InfoOnline,
      },
      {
        path: "/singlesession",
        component: SingleSession,
      },
      {
        path: "/us",
        component: WirView,
      },
      {
        path: "/impressum",
        component: ImpressumView,
      },
      {
        path: "/datenschutz",
        component: DatenSchutz,
      },
      {
        path: "/agb",
        component: AGBView,
      },
      {
        path: "/welcome",
        component: WelcomeView,
      },
      {
        // path: "*",
        path: "/:catchAll(.*)",
        component: LandingView,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // mode: "history",
});

export default router;
